import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useUserStore = defineStore("user", () => {
  //REFS
  const _user = ref();
  const _livelloLogin = ref();
  const _userList = ref();
  const _userOptionsList = ref();

  //COMPUTED
  const user = computed(() => {
    if (!_user.value && localStorage.getItem("DR_Overlend_user"))
      setUser(JSON.parse(localStorage.getItem("DR_Overlend_user")));
    return _user.value;
  });
  const livelloLogin = computed(() => {
    if (
      !_livelloLogin.value &&
      localStorage.getItem("DR_Overlend_livelloLogin")
    )
      setLivelloLogin(localStorage.getItem("DR_Overlend_livelloLogin"));
    return _livelloLogin.value;
  });
  const userList = computed(() => {
    if (!_userList.value && localStorage.getItem("DR_Overlend_userList"))
      setUserList(JSON.parse(localStorage.getItem("DR_Overlend_userList")));
    return _userList.value;
  });
  const userOptionsList = computed(() => {
    if (
      !_userOptionsList.value &&
      localStorage.getItem("DR_Overlend_userOptionsList")
    )
      setUserOptionsList(
        JSON.parse(localStorage.getItem("DR_Overlend_userOptionsList"))
      );
    return _userOptionsList.value;
  });

  //FUNCTIONS
  function setUser(value) {
    localStorage.setItem("DR_Overlend_user", JSON.stringify(value));
    _user.value = value;
  }
  function setLivelloLogin(value) {
    console.log("setLivelloLogin", value, livelloLogin, livelloLogin.value);
    localStorage.setItem("DR_Overlend_livelloLogin", value);
    _livelloLogin.value = value;
  }
  function setUserList(value) {
    localStorage.setItem("DR_Overlend_userList", JSON.stringify(value));
    _userList.value = value;
  }
  function setUserOptionsList(value) {
    localStorage.setItem("DR_Overlend_userOptionsList", JSON.stringify(value));
    _userOptionsList.value = value;
  }

  //RESET
  function resetUser() {
    localStorage.removeItem("DR_Overlend_user");
    _user.value = null;
  }
  function resetLivelloLogin() {
    localStorage.removeItem("DR_Overlend_livelloLogin");
    _livelloLogin.value = null;
  }
  function resetUserList() {
    localStorage.removeItem("DR_Overlend_userList");
    _userList.value = null;
  }
  function resetUserOptionsList() {
    localStorage.removeItem("DR_Overlend_userOptionsList");
    _userOptionsList.value = null;
  }

  //RETURN
  return {
    user,
    setUser,
    resetUser,

    livelloLogin,
    setLivelloLogin,
    resetLivelloLogin,

    userList,
    setUserList,
    resetUserList,

    userOptionsList,
    setUserOptionsList,
    resetUserOptionsList,
  };
});
